<template>
  <div class="contactUsBody">
    <DuList type="contactUsMessagesCp" />
  </div>
</template>

<script>
import {reactive} from "vue";
import DuList from "../../components/DuList";

export default {
  name: "ContactUsMessages",
  components: {DuList},
  setup() {
    const state = reactive({

    });
    return {
      state,
    };
  },
}
</script>

<style scoped>
.contactUsBody{
  display: flex;
  flex-direction: column;
}
</style>